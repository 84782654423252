import apiTool from '@/command/apiTool'
import { getAction, postAction, delAction } from '@/command/netTool'
import DrawerForm from '@/components/DrawerForm'
import { Message } from 'ant-design-vue'
import { Button } from 'ant-design-vue'

let goodsTypes = []
let pointGoods = []
let pointGoodsPage = []

let pointList = {}
let sllPointList = {}
let scPointList = {}
let formData = { pointRules: '1' }
let formUpdata = null
let pageTotal = 10,
  pageCurrent = 1
async function onFetchInit(data) {
  const { total, records } = data
  pageTotal = total
  pointGoodsPage = records
  const [goodsTypesRes, switchEcho] = await Promise.all(
    ['/point_goods/goods_types', '/point_goods/switch'].map(e => getAction(e))
  )
  const [pointGroupList] = await Promise.all(['/point_goods_group/list'].map(e => postAction(e)))

  goodsTypes = goodsTypesRes.data || []

  pointList = pointGroupList.data || {}
  sllPointList =
    pointList.sllGroups
      .sort((a, b) => {
        return a.sort - b.sort
      })
      .map(item => {
        return {
          name: item.groupName,
          value: item.id
        }
      }) || []
  scPointList =
    pointList.scGroups
      .sort((a, b) => {
        return a.sort - b.sort
      })
      .map(item => {
        return {
          name: item.groupName,
          value: item.id
        }
      }) || []

  let form = {
    ...data,
    mallPage: switchEcho.data == '0' ? '1' : '0'
  }
  return {
    form
  }
}

async function getPointGoods(type) {
  await getAction(`/point_goods/query?type=${type}`).then(res => {
    pointGoods =
      res.data.goodsInfoList.map(item => {
        return {
          ...item,
          name: item.goodsName,
          value: item.id
        }
      }) || []
    formData.productType = res.data.productType
  })
}

function getForm(form, { update }) {
  formUpdata = update
  return [
    {
      form: [
        {
          name: '商品类型',
          key: 'goodsType',
          type: 'select',
          typeData: goodsTypes,
          props: {
            showSearch: true
          },
          onChange: val => {
            getPointGoods(val).then(update)
            form.goodsId = ''
            form.goodsInventory = '0'
            form.goodsName = ''
            form.goodsPic = ''
          },
          rules: [
            {
              required: true,
              message: '请输入商品类型'
            }
          ]
        },
        {
          name: '商品名称',
          type: 'select',
          typeData: pointGoods,
          key: 'goodsId',
          props: {
            showSearch: true
          },
          onChange: val => {
            let item = pointGoods.filter(e => e.id === val)[0]
            form.goodsInventory = item.inventory
            form.goodsPic = item.goodsPic
            form.originPrice = item.goodsPrice
            form.goodsName = item.goodsName
            formUpdata()
          },
          rules: [
            {
              required: true,
              message: '请输入商品名称'
            }
          ]
        },
        {
          name: '上传图片',
          type: 'upload',
          key: 'goodsPic',
          rules: [
            {
              required: true,
              message: '请上传图片'
            }
          ]
        },
        {
          name: '商品类别',
          type: 'radioButton',
          key: 'productType',
          disabled: true,
          typeData: [
            {
              name: '虚拟商品',
              value: '1'
            },
            {
              name: '实物商品',
              value: '2'
            }
          ],
          rules: [
            {
              required: true,
              message: '请选择商品类别'
            }
          ]
        },
        {
          name: '商城',
          type: 'checkBoxButton',
          key: 'groupCsIds',
          typeData: scPointList
        },
        {
          name: '收乐乐',
          type: 'checkBoxButton',
          key: 'groupSllIds',
          typeData: sllPointList
        },
        {
          name: '兑换规则',
          type: 'radioButton',
          key: 'pointRules',
          typeData: [
            {
              name: '纯积分兑换',
              value: '1'
            },
            {
              name: '积分+金额兑换',
              value: '2'
            }
          ],
          rules: [
            {
              required: true,
              message: '请选择兑换规则'
            }
          ]
        },
        {
          type: 'row',
          cols: 24,
          children: [
            {
              display: true,
              type: 'text',
              cols: 3,
              align: 'center'
            },
            {
              display: true,
              type: 'input',
              cols: 4,
              offset: 4,
              placeholder: ' ',
              value: form.pointPrice,
              onChange: val => {
                formData.pointPrice = val.replace(/[^0-9.]/g, '').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
              }
            },
            {
              display: true,
              type: 'text',
              value: '积分',
              cols: 2,
              align: 'center'
            },
            {
              display: form.pointRules == '2',
              type: 'input',
              cols: 4,
              placeholder: ' ',
              value: form.rmbPrice,
              onChange: val => {
                formData.rmbPrice = val.replace(/[^0-9.]/g, '').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
              }
            },
            {
              display: form.pointRules == '2',
              type: 'text',
              value: '元',
              cols: 2,
              align: 'center'
            }
          ].filter(e => e.display)
        },
        {
          name: '商品库存',
          type: 'input',
          remark: '注：当前商品库存' + (form.goodsInventory || 0),
          key: 'inventory',
          rules: [
            {
              required: true,
              message: '请输入商品库存',
              validator: (rule, value, callback) => {
                const rep = /(^[0-9]\d*$)/
                if (!value) {
                  rule.message = '请输入商品库存'
                  callback('请输入商品库存')
                  return
                }
                if (!rep.test(value)) {
                  callback('商品库存为正整数')
                  return
                }
                callback()
              }
            }
          ]
        },
        {
          name: '可兑换次数',
          type: 'input',
          remark: '注：0表示不限制',
          key: 'quantityConvertible',
          rules: [
            {
              required: true,
              message: '请输入可兑换次数',
              validator: (rule, value, callback) => {
                const rep = /(^[0-9]\d*$)/
                if (!value) {
                  rule.message = '请输入可兑换次数'
                  callback('请输入可兑换次数')
                  return
                }
                if (!rep.test(value)) {
                  rule.message = '可兑换次数为正整数'
                  callback('可兑换次数为正整数')
                  return
                }
                callback()
              }
            }
          ]
        },
        {
          name: '排序',
          type: 'input',
          remark: '注：数字越小越靠前，数字重复则最新添加的靠前',
          key: 'sort',
          // rulesText: '排序为正整数',
          rules: [
            {
              required: true,
              message: '请输入排序',
              validator: (rule, value, callback) => {
                const rep = /(^[0-9]\d*$)/
                if (!value) {
                  rule.message = '请输入排序'
                  callback('请输入排序')
                  return
                }
                if (!rep.test(value)) {
                  rule.message = '排序为正整数'
                  callback('排序为正整数')
                  return
                }
                callback()
              }
            }
          ]
        },
        {
          name: '兑换须知',
          type: 'textArea',
          key: 'instructions',
          rules: [
            {
              required: true,
              message: '请输入兑换须知'
            }
          ]
        },
        {
          name: '温馨提示',
          type: 'textArea',
          key: 'warmPrompt',
          rules: [
            {
              required: true,
              message: '请输入温馨提示'
            }
          ]
        }
      ]
    }
  ]
}

function getFormType(form) {
  return [
    {
      title: '收乐乐',
      form: [
        {
          type: 'table',
          showFootButton: true,
          showRowSelect: false,
          showPagination: false,
          havedrag: true,
          onAddData: () => {
            return {
              groupName: '',
              sort: ''
            }
          },
          columns: [
            {
              dataIndex: 'sort',
              align: 'left',
              title: '拖动排序',
              width: 80,
              customRender: function(text, records, index, h) {
                return <a-icon type="menu" />
              }
            },
            {
              dataIndex: 'groupName',
              align: 'left',
              title: '类型名称',
              width: '70%',
              customRender: function(text, records, index, h) {
                const onInput = data => {
                  records.groupName = data.target.value
                }
                return <a-input placeholder="类型名称" onChange={onInput} value={text} />
              }
            },
            {
              dataIndex: 'a10',
              align: 'center',
              width: 50,
              title: '操作',
              tableType: 'editDelete',
              unShowEditBtn: true
            }
          ],
          dataSource: form.sllGroups
        }
      ]
    },
    {
      title: '商城',
      form: [
        {
          type: 'table',
          showFootButton: true,
          showRowSelect: false,
          showPagination: false,
          havedrag: true,
          onAddData: val => {
            return {
              groupName: '',
              sort: ''
            }
          },
          columns: [
            {
              dataIndex: 'sort',
              align: 'left',
              title: '拖动排序',
              width: 80,
              customRender: function(text, records, index, h) {
                return <a-icon type="menu" />
              }
            },
            {
              dataIndex: 'groupName',
              align: 'left',
              title: '类型名称',
              width: '70%',
              customRender: function(text, records, index, h) {
                const onInput = data => {
                  records.groupName = data.target.value
                }
                return <a-input placeholder="类型名称" onChange={onInput} value={text} />
              }
            },
            {
              dataIndex: 'a10',
              align: 'center',
              width: 50,
              title: '操作',
              tableType: 'editDelete',
              unShowEditBtn: true
            }
          ],
          dataSource: form.scGroups
        }
      ]
    }
  ]
}

function onOpenType({ getDetail }) {
  apiTool.showDrawer({
    title: '分组类型',
    view: DrawerForm,
    width: '600px',
    viewProps: {
      formProps: {
        labelCol: { span: 4 },
        wrapperCol: { span: 24 }
      },
      // form: { ...records },
      form: pointList,
      data: getFormType
    },
    success({ data, setHidden }) {
      let sllCheck = data.sllGroups.filter(e => !e.groupName)
      let scCheck = data.scGroups.filter(e => !e.groupName)
      if (sllCheck.length || scCheck.length) return Message.error('类型名称不能为空!')
      data.sllGroups = data.sllGroups?.map((item, i) => {
        return {
          ...item,
          sort: i
        }
      })
      data.scGroups = data.scGroups?.map((item, i) => {
        return {
          ...item,
          sort: i
        }
      })

      postAction('/point_goods_group/addOrModify', {
        ...data
      }).then(res => {
        Message.success('保存成功')
        getDetail()
        setHidden()
      })
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '取消',
          onClick: close
        },
        {
          name: '保存',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

function onOpen({ getDetail }, title) {
  apiTool.showDrawer({
    title: title,
    view: DrawerForm,
    width: '800px',
    viewProps: {
      formProps: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      },
      form: formData,
      data: getForm
    },
    success({ data, setHidden }) {
      console.log(formData)
      const rep = /(^[0-9]\d*$)/
      // if(!formData.goodsPic || !rep.test(formData.sort) || !rep.test(formData.quantityConvertible) || !rep.test(formData.inventory)) return
      if (!rep.test(formData.sort) || !rep.test(formData.quantityConvertible) || !rep.test(formData.inventory)) return
      if (formData.pointRules === '2' && !formData.rmbPrice) return Message.error('兑换金额不能为空')
      const groupIds = [...(formData.groupCsIds || []), ...(formData.groupSllIds || [])]
      const params = {
        ...formData,
        groupIds: groupIds?.toString() || '',
        rulesDescribe: formData.pointPrice + '积分' + (formData.pointRules === '2' ? `+${formData.rmbPrice}元` : '')
      }
      postAction('/point_goods/addOrModify', params).then(res => {
        Message.success('保存成功')
        getDetail()
        setHidden()
      })
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '取消',
          onClick: close
        },
        {
          name: '保存',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

export default function({ getDetail }) {
  return {
    type: 'cardFormTable',
    url: '/point_goods/page',
    params: {
      size: 6,
      current: pageCurrent
    },
    onFetchInit,
    data: [
      {
        form: [
          {
            name: '积分商城',
            type: 'switch',
            key: 'mallPage',
            labelCol: { span: 3 },
            wrapperCol: { span: 20 },
            onChange: data => {
              getAction(`/point_goods/switch/${+data}`).then(res => {
                if (+data) {
                  Message.success('开启积分商城成功!')
                } else {
                  Message.success('关闭积分商城成功!')
                }
              })
            }
          }
        ]
      },
      {
        form: [
          {
            label: '添加',
            type: 'button',
            cols: 3,
            props: {
              type: 'primary',
              style: {
                width: '100px'
              }
            },
            onClick: () => {
              formData = { pointRules: '1' }
              onOpen({ getDetail }, '添加商品')
            }
          },
          {
            label: '分组类型',
            type: 'button',
            cols: 3,
            props: {
              type: 'primary',
              style: {
                width: '100px'
              }
            },
            onClick: () => {
              onOpenType({ getDetail })
            }
          },
          {
            type: 'table',
            labelCol: { span: 0 },
            wrapperCol: { span: 24 },
            dataSource: pointGoodsPage,
            showFootButton: false,
            showRowSelect: false,
            showPagination: true,
            pagination: {
              pageSize: 6,
              total: pageTotal,
              hideOnSinglePage: true,
              showSizeChanger: false,
              onChange: (page, pageSize) => {
                pageCurrent = page
                getDetail()
              }
            },
            columns: [
              {
                dataIndex: 'goodsName',
                title: '名称',
                align: 'left',
                customRender: function(text, records, index, h) {
                  return (
                    <div class="product-order-item-copy">
                      <div>{text}</div>
                    </div>
                  )
                }
              },
              {
                dataIndex: 'pointPrice',
                title: '积分价格',
                align: 'left',
                customRender: function(text, records, index, h) {
                  return (
                    <div>
                      {text}积分{records.pointRules == 2 ? `+${records.rmbPrice}元` : ''}
                    </div>
                  )
                }
              },
              {
                dataIndex: 'groupNameList',
                title: '产品类型',
                getData: records => records.groupNameList.map(e => e),
                type: 'tagGroup',
                align: 'left',
                onExport: (text, records) => {
                  let arr = text.map(e => {
                    return e.standardsName
                  })
                  return arr.toString()
                }
              },

              {
                dataIndex: 'sort',
                title: '排序',
                align: 'left',
                width: 80
              },
              {
                dataIndex: 'inventory',
                title: '库存',
                align: 'left',
                width: 80
              },
              {
                dataIndex: 'status',
                title: '状态',
                type: 'badge',
                onExport: records => {
                  console.log(records, 'recordsrecords')
                  return ['上架', '下架'][records]
                },
                filterMultiple: false,
                render(data) {
                  return {
                    showDot: true,
                    name: data == 1 ? '上架' : '下架',
                    color: data == 1 ? 'green' : 'red'
                  }
                }
              },
              {
                title: '操作',
                customRender: function customRender(text, records, index, h) {
                  return (
                    <div>
                      <Button
                        ghost={true}
                        type="primary"
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                          if (records.status == 1) {
                            getAction(`/point_goods/takeDown/${records.id}`).then(res => {
                              Message.error('下架成功!')
                              getDetail()
                            })
                          } else {
                            getAction(`/point_goods/takeUp/${records.id}`).then(res => {
                              Message.success('上架成功!')
                              getDetail()
                            })
                          }
                        }}
                      >
                        {records.status == 1 ? '下架' : '上架'}
                      </Button>
                      <Button
                        ghost={true}
                        type="primary"
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                          getAction(`/point_goods/detail/${records.id}`).then(res => {
                            formData = res.data

                            getPointGoods(formData.goodsType).then(() => {
                              let item = pointGoods.filter(e => e.id === res.data.goodsId)[0]
                              formData.goodsInventory = item?.inventory || '0'
                              formData.goodsName = item?.goodsName || ''
                              formUpdata()
                            })
                            const scGroups = pointList.scGroups.map(e => e.id)
                            const sllGroups = pointList.sllGroups.map(e => e.id)

                            formData.groupCsIds = scGroups.filter(item => res.data.groupIdList.includes(item))
                            formData.groupSllIds = sllGroups.filter(item => res.data.groupIdList.includes(item))
                            onOpen({ getDetail }, '编辑商品')
                          })
                        }}
                      >
                        编辑
                      </Button>
                      <Button
                        ghost={true}
                        type="primary"
                        onClick={() => {
                          delAction(`/point_goods/delete/${records.id}`).then(res => {
                            Message.success('删除成功!')
                            getDetail()
                          })
                        }}
                      >
                        删除
                      </Button>
                    </div>
                  )
                }
              }
            ]
          }
        ]
      }
    ]
  }
}
